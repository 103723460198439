<template>
    <div>
        <v-card>
            <v-card-actions class="mx-3">
                <v-spacer></v-spacer>
                <v-btn class="mr-3 mb-3 white--text" small color="green" depressed @click="export2xls">Export to XLS</v-btn>
            </v-card-actions>
            <v-card-subtitle>
                <div style="text-align:center; font-weight:bold; font-size:16px; padding:10px">{{formTitle}}</div>

                <v-row class="compact-form my-2">
                    <v-col cols="1" class="pt-5">
                        Tanggal
                    </v-col>
                    <v-col cols="1" class="pt-5">
                        Dari
                    </v-col>
                    <v-col cols="2" class="">
                        <v-menu v-model="dateMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="reportDate" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto" dense outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="reportDate"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="1" class="pt-5">
                        Sampai
                    </v-col>
                    <v-col cols="2" class="">
                        <v-menu v-model="dateMenu2" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="reportDate2" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto" dense outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="reportDate2"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="2">
                        <v-btn depressed color="primary" @click="runReport">Run</v-btn>
                    </v-col>
                </v-row>                

            </v-card-subtitle>

            <v-card-text class="ml-3 mr-3">

                <div id="printresep" style="overflow:auto">
                    <!-- <table style="width:98%; margin: 0px;" class="pdf-item" ref="tableBOM"> -->
                    <v-simple-table fixed-header height="400px" id="variantTbl" ref="tableBOM">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th><b>Volume</b></th>
                                    <th><b>Nama Produk</b></th>
                                    <th v-for="dt in dateCols" :key="dt"><b>{{dt | dateheader}}</b></th>
                                    <th><b>Total</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(vol, q, idx) in printObj">
                                <tr :key="idx">
                                    <td class="font-weight-bold" :colspan="2+dateCols.length+1"> Volume {{q}} ml</td>
                                </tr>
                                <tr v-for="(prod, prodname) in vol" :key="prod.production_date">
                                    <td>{{q}} ml</td>
                                    <td>{{prodname}}</td>
                                    <td v-for="dates in prod.dates" :key="dates.date">
                                        {{(dates.qty==0)?'':dates.qty}}
                                    </td>
                                    <td class="font-weight-bold">
                                        {{(totalVolProd[q][prodname]==0)?'':totalVolProd[q][prodname]}}
                                    </td>
                                </tr>
                                <tr :key="q">
                                    <td colspan="2" class="font-weight-bold">
                                        Total {{q}} ml 
                                    </td>
                                    <td v-for="(date, idd) in totalVol[q]" :key="idd" class="font-weight-bold">
                                        {{(date==0)?'':date}}
                                    </td>
                                    <td class="font-weight-bold bgcol">
                                        {{calcTotalVol(totalVol[q])}}
                                    </td>
                                </tr>
                                </template>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <!-- </table> -->
                </div>
            </v-card-text>
        </v-card>
        <v-overlay :value="onLoad" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        

    </div>
</template>

<script>
import moment from 'moment'
import { utils, writeFileXLSX } from 'xlsx';

export default {

    data: () => ({
        dialog: false,
        onLoad: false,
        printObj: [],
        dateMenu: false,
        dateMenu2: false,
        reportDate: null,
        reportDate2: null,
        dateCols: [],
        totalVol: {},
        totalVolProd: {},
        formTitle: ''
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      }
    },

    watch: {
    },

    filters: {
        dateheader: function (date) {
            return moment(date).format('D-MMM-YY');
        },
        numbers: function (number) {
            return Number(number).toLocaleString()
        },
    },

    async created() {
        if (this.$route.params.type=='prod')
            this.formTitle = 'Report Hasil Produksi'
        else if (this.$route.params.type=='order')
            this.formTitle = 'Report Order PPIC'
        else if (this.$route.params.type=='extra')
            this.formTitle = 'Report Botol Extra'
        else if (this.$route.params.type=='fail')
            this.formTitle = 'Report Produk Gagal'
    },

    methods: {
        calcTotalVol(numbers) {
            // console.log(numbers)
            let total = 0
            for (let key in numbers) {
                total += Number(numbers[key])
            }
            if (total==0) total = ''
            
            return total
        },
        createDateCols() {
            const startDate = new Date(this.reportDate);
            const endDate = new Date(this.reportDate2);

            const currentDate = new Date(startDate);
            this.dateCols = []
            while (currentDate <= endDate) {
                this.dateCols.push(currentDate.toISOString().split('T')[0]); 
                // Increment the current date by 1 day
                currentDate.setDate(currentDate.getDate() + 1);
            }

        },
        async runReport() {
            this.createDateCols()
            this.onLoad = true
            let raw = ''
            if (this.$route.params.type=='prod')
                raw = (await this.$store.dispatch('getObjs',{apidomain:'others/rptproduction/' + this.reportDate + '/', parameter:this.reportDate2})).data
            else if (this.$route.params.type=='order')
                raw = (await this.$store.dispatch('getObjs',{apidomain:'others/rptorder/' + this.reportDate + '/', parameter:this.reportDate2})).data
            else if (this.$route.params.type=='extra')
                raw = (await this.$store.dispatch('getObjs',{apidomain:'others/rptbotolextra/' + this.reportDate + '/', parameter:this.reportDate2})).data
            else if (this.$route.params.type=='fail')
                raw = (await this.$store.dispatch('getObjs',{apidomain:'others/rptprodukgagal/' + this.reportDate + '/', parameter:this.reportDate2})).data

            // grouping array into object by volume then product name
            const groupedData = await raw.reduce((result, { size, name, ...rest }) => {
                if (!result[size]) {
                    result[size] = {};
                }
                
                if (!result[size][name]) {
                    result[size][name] = [];
                }
                
                result[size][name].push(rest);
                return result;
            }, {});

            // fill date columns
            for (const [vol, volObj] of Object.entries(groupedData)) {
                // console.log(`${vol}: ${volObj}`);
                // initialize total volume per date
                this.totalVol[vol] = {}
                this.dateCols.forEach(element => {
                    this.totalVol[vol][element] = 0
                });

                // initialize total volume per product
                this.totalVolProd[vol] = {}

                for (let [prodName, prodObj] of Object.entries(volObj)) {
                    // console.log(prodName)
                    this.totalVolProd[vol][prodName] = 0
                    // console.log(prodObj)
                    const startDate = new Date(this.reportDate);
                    const endDate = new Date(this.reportDate2);
                    const currentDate = new Date(startDate);
                    let dates = []
                    while (currentDate <= endDate) {
                        const sDate = currentDate.toISOString().split('T')[0]
                        const selDate = prodObj.find(el=> el.production_date==sDate)
                        const qty = (selDate)?selDate.qty_prod:0
                        dates.push({
                            date: sDate,
                            qty: qty
                        })
                        this.totalVol[vol][sDate] += Number(qty)
                        this.totalVolProd[vol][prodName] += Number(qty)
                        // Increment the current date by 1 day
                        currentDate.setDate(currentDate.getDate() + 1);
                    }

                    this.$set(prodObj, 'dates', dates)
                }

            }

            console.log(this.totalVolProd)

            this.printObj = groupedData
            this.onLoad = false
        },
        export2xls() {
            console.log(this.$refs.tableBOM.$el.firstChild.firstChild)
            const wb = utils.table_to_book(this.$refs.tableBOM.$el.firstChild.firstChild)
            writeFileXLSX(wb, "Report-Production.xlsx")

        },
    }    
}
</script>

<style scoped>
table, th, td {
  border: 1px solid grey;
  border-collapse: collapse;
}
#printresep th, td {
  padding: 10px;
  font-size: 12px;
}
.bgcol {
    background-color: lightgrey
}
</style>